<template>
	<el-dialog class="dialog" title="添加下级" :visible.sync="visiable" width="30%">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="120px">
				<div style="display: flex;" v-for="(item,i) in names">
					<el-form-item label="名称：" style="flex: 1;">
						<el-input v-model="names[i]"></el-input>
					</el-form-item>
					<div style="width: 150px;margin-left: 30px;">
						<el-button icon="el-icon-minus" @click="removeOne(names[i],i)" v-if="names.length>1">
						</el-button>
						<el-button icon="el-icon-plus" @click="addOne(names[i],i)" v-if="i==names.length-1"></el-button>
					</div>
				</div>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				loading: false,
				formData: {},
				names: [""],
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.names = [""];
			},
			removeOne(item, i) {
				this.names.splice(i, 1);
			},
			addOne(item, i) {
				if (item == "") {
					this.$message.warning("请先填写这一行的名称")
					return;
				}
				this.names.push("");
			},
			//提交表单
			submit() {
				for (let item of this.names) {
					if (item == "") {
						this.$message.warning("请填写所有行")
						return;
					}
				}
				this.loading = true;
				this.$postData("/user-api/ent/dept/save", {
					id: this.dialogObj.id,
					names: this.names
				}).then((res) => {
					if (res.code == 1000) {
						this.loading = false;
						this.$message.success(res.msg);
						this.$parent.list();
						this.close();
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.cascader {
			display: block;
		}
	}
</style>
